<template>
	<div class="full-height">
		<div
			class="mt-10 bg-white pa-10 full-height overflow-y-auto"
		>
			<ul
				class="justify-start gap-10 flex-row-wrap"
			>
				<li
					v-for="(item, index) in items"
					:key="'item_' + index"
					class="inline mr-10"
				>
					<input v-model="item.name" class="input-box " />
					<button
						v-if="items.length > 0 && index < items.length - 1"
						@click="items.splice(index, 1)"
					><v-icon class="color-red">mdi-close</v-icon></button>
					<button
						v-if="index == items.length - 1"
						class=""
						@click="items.push({name:''})"
					><v-icon class="color-blue">mdi-plus-circle</v-icon></button>
				</li>
			</ul>

			<div class="text-right mt-10">
				<button
					class="btn-inline btn-primary"
					@click="postSave"
				>저장</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'HistoryProduct'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: '수기용 상품 목록'
					,top: true
					,title: true
				}
				, items: [
					{ name: ''}
				]
			}
		}
		, computed: {
			names: function(){
				let t = ''
				this.items.filter((item) => {
					t += item.name + ','
				})
				return t
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					let result = await this.$request.init({
						method: 'post'
						, url: '/admin/getHistoryProduct'
					})
					if(result.success){
						this.items = result.data
					}else{
						throw result.message
					}
				}catch (e){
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postSave: async function(){

				try{
					this.$bus.$emit('on', true)
					let result = await this.$request.init({
						method: 'post'
						, url: '/admin/postHistoryProduct'
						, data: {
							names: this.names
						}
					})
					if(result.success){
						await this.getData()
						this.$bus.$emit('notify', { type: 'success', message: result.message})
					}else{
						throw result.message
					}
				}catch (e){
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>